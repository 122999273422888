import { MarketplaceItem } from "./marketplaceTypes";
import ItemCarouselPanel from "./ItemCarouselPanel";

import styled from "styled-components";

const ItemCarouselWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const CategoryTitle = styled.div`
  padding: 0.53125rem 0;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.03125rem;
`;

type ItemCarouselProps = {
  category: string;
  itemStack: MarketplaceItem[];
}

function ItemCarousel({ category, itemStack }: ItemCarouselProps) {
  return(
    <ItemCarouselWrapper>
      <CategoryTitle>{category}</CategoryTitle>
      <ItemCarouselPanel category={category} itemStack={itemStack} />
    </ItemCarouselWrapper>
  );
}

export default ItemCarousel;
