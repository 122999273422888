import { MarketplaceItem } from "./marketplaceTypes";

import { FixedSizeList as List } from "react-window";
import ItemCard from "./ItemCard";

type ItemCarouselPanelProps = {
  category: string;
  itemStack: MarketplaceItem[];
}

function ItemCarouselPanel({ category, itemStack }: ItemCarouselPanelProps) {
  return(
    <List
      itemSize={170}
      layout={"horizontal"}
      direction={"ltr"}
      height={275}
      width={window.innerWidth - 16}
      itemData={itemStack}
      itemCount={itemStack.length}
    >
      {({data, index, style}: {data: MarketplaceItem[], index: number, style: any}) => {
        const item = data[index];
        return <ItemCard innerStyle={style} item={item} key={`${category}_itemCard_${index}`}/>
      }}
    </List>
  );
}

export default ItemCarouselPanel;
